<template>
  <div v-if="current === 'index'">
        <jy-query class="query_area" :model="formData">
            <jy-query-item label="企业名称" prop="cName">
                <el-input v-model="formData.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <el-input v-model="formData.orgNa" @focus="selectInstitutions"></el-input>
            </jy-query-item>
            <jy-query-item label="卡号" prop="cardNo">
                <el-input v-model="formData.cardNo"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" v-on:click="getList">查询</el-button>
                <el-button size="small" type="primary" v-on:click="resetFun">重置</el-button>
                <!-- <el-button size="small" type="primary">导出</el-button> -->
            </template>
        </jy-query>
        <div>搜索结果合计：订单数0笔，交易消费总金额0元，交易充值总金额0元，余额0元</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column label="企业名称" prop="cName" min-width="120"></jy-table-column>
                <jy-table-column label="卡号" prop="cardNo" min-width="140"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" min-width="140"></jy-table-column>
                <jy-table-column label="单位注册地址" prop="addr" min-width="150"></jy-table-column>
                <jy-table-column label="联系人" prop="contact" min-width="120"></jy-table-column>
                <jy-table-column label="联系电话" prop="conPhone" min-width="130"></jy-table-column>
                <jy-table-column label="消费总金额(元)" prop="outAmount" min-width="120"></jy-table-column>
                <jy-table-column label="充值总金额(元)" prop="inAmount" min-width="120"></jy-table-column>
                <jy-table-column label="余额(元)" prop="saveAmount" min-width="120"></jy-table-column>
                <jy-operate :list="operationList" width="130"></jy-operate>
            </jy-table>
        </div>
        <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
        ></jy-pagination>
    <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
  </div>
    <detail v-else-if="current === 'detail'" ref="detail" title="企业台账 > 详情" @switch="switchPage" />
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import detail from "@/components/pages/admin/finance/detail.vue";
export default {
    components: {
        detail,
        institutionsTree
    },

    data() {
        return {
            current: 'index',
            total: 0,
            pageSize: 10,
            pageIndex: 1,
            loading: false,
            operationList: [],
            dataList: [],
            formData:{
                cName:'',
                orgNa:'',
                orgId:'',
                cardNo:'',
            },
            option:null,
        }
    },

    created() {
        this.setOperationList();
        this.getList()
    },

    methods: {
        getList(){
            let url = '/finance/accountUnitMsg'
            const { cName, cardNo, orgId } = this.formData
            let params = {
                unitInfo: {
                    cName,
                    cardNo,
                    orgId
                },
                pageIndex:this.pageIndex,
                pageSize:this.pageSize
            }
            this.loading = true
            this.$http.post(url,params).then(({detail})=>{
                console.log(detail)
                this.total = detail.total
                this.dataList = detail.data.map((one=>{
                    return{
                        ...one,
                        ...one.unitInfo,
                        orgNa:one.org && one.org.orgNa
                    }
                }))
            }).finally(()=>{
                this.loading = false
            })
        },

        selectInstitutions() {
            this.$refs.institutions.init();
        },

        addInstitutions(data) {
            this.formData.orgId = data.orgId;
            this.formData.orgNa = data.orgNa;
        },


        handleSizeChange(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },

        handleCurrentChange(pageIndex) {
            this.pageIndex = pageIndex
            this.getList()
        },

        onDetail(row) {
            this.current = 'detail',
            this.options={
                ...row
            }
        },

        switchPage() {
            this.current = 'index'
        },

        resetFun(){
            this.pageIndex = 1;
            this.pageSize = 10;
            Object.keys(this.formData).forEach(key => {
                this.formData[key] = ''
            })
            this.getList()
        },

        setOperationList() {
            this.operationList = [
                {
                    name: "详情",
                    icon: "el-icon-edit-outline",
                    fun: this.onDetail,
                    isShow: () => {
                        return true;
                    }
                }
            ]
        }
    }
}
</script>

<style>

</style>